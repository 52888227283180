import { ResumeBuilder } from "./resumeBuilder"

export enum FetchState {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type Postdata = {
    resumeBuilder: ResumeBuilder

}