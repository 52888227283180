import React, { Component, useEffect, useMemo, useState } from 'react';
import '../styles/fetchexperience.css';
import { useGetResume } from '../services/ResumeService'
import { FetchState, Postdata } from '../models/types'
import { withApplicationInsights } from '../components/telemetry';



const Experience = () => {
    const [posts, fetchState, getPosts] = useGetResume();
    useEffect(() => {
        getPosts();
    }, [])

    return (
        <div className="container-fluid p-0">
            {fetchState === FetchState.LOADING && (
                <>
                    <p>Fetching posts...</p>
                </>
            )}
            {fetchState === FetchState.ERROR && (
                <>
                    <p>Oops! Something went wrong. Please try again.</p>
                </>
            )}
            {fetchState === FetchState.SUCCESS && (
                <>
                    <section className="resume-section" id="experience">
                        <div className="resume-section-content">
                            <h2 className="mb-5">Experience</h2>
                            <div className="experience-wrapper">
                                {posts!.employmentHistory.map((entry, index) => (
                                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5 employer-wrapper" key={index}>
                                        <div className="company-logo-div">
                                            <img className="img-fluid img-profile rounded-circle mx-auto mb-2 companylogo" src={entry.image} alt="..." />
                                        </div>
                                        <div className="company-info-div">
                                            <h3 className="mb-0">{entry.title}</h3>
                                            <div className="subheading mb-3">{entry.employer}</div>
                                            <ul className="section-content">
                                                {entry.bullets.map((bullet, index) => (
                                                    <li key={index}>{bullet.bullet}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>

    )
}
export default withApplicationInsights(Experience, 'Experience')


