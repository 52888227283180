import axios, { AxiosInstance } from 'axios';
import {useState} from 'react';
import { ResumeBuilder } from '../models'
import { FetchState, Postdata } from '../models/types'
import config from "../config"



export function useGetResume(){
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
    const [posts, setPosts] = useState<ResumeBuilder>();
    const service = '/resume'
     
        const client = axios.create({
            baseURL: `${config.api.baseUrl}`
        });

    const getPosts = async () => {
        

        try {
            setFetchState(FetchState.LOADING);
            const res = await client.get(service);
            const resData = res.data as ResumeBuilder;

            setPosts(resData);
            setFetchState(FetchState.SUCCESS);

        }
        catch (err) {
            setFetchState(FetchState.ERROR);
        }
    };
    return [posts, fetchState, getPosts] as const
}
