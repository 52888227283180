import React, { Component } from 'react';

import '../styles/fetchskills.css';
export class Skills extends Component {
    static displayName = Skills.name;

    render() {
        return (
            <div className="container-fluid p-0"> 
                <section className="resume-section" id="skills">
                    <div className="resume-section-content">
                        <h2 className="mb-5">Skills</h2>
                        <div className="subheading mb-3">Programming Languages & Tools</div>
                        <ul className="list-inline dev-icons">
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/csharp.png')} alt="C Sharp" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/netcorre.png')} alt=".NET" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/OOP.png')} alt="Object Oriented Programming" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/JS.png')} alt="Java Script" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/REST.png')} alt="REST API's" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/graphql.png')} alt="GraphQL" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/azure.png')} alt="Azure" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/MVC.png')} alt="MVC" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/react.png')} alt="REACT.JS" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/docker.png')} alt="Docker" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/git.png')} alt="GIT" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/SSMS.png')} alt="Sql Server Management Studio" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/VS.png')} alt="Visual Studio" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/insomnia.png')} alt="Insomnia" /></li>
                            <li className="list-inline-item"><img className="img-fluid img-profile rounded-circle mx-auto mb-2 skills-image" src={require('../images/logos/Postman.png')} alt="Postman" /></li>
                        </ul>
                    </div>
                   {/* NEED TO ADD CERTIFICATIONS HERE*/}
                </section>
            </div>
        );
    }
}
