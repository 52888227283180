import React, { Component, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/navmenu.css';
import { withApplicationInsights } from '../components/telemetry';

function NavMenu() {
    const [collapsed, setcollapsed] = useState(false);
    const toggleNavbar = () => {        
            setcollapsed(!collapsed)       
    }
    const displayName = NavMenu.name;
    return (
        <header>
            <Navbar className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
                <NavbarBrand tag={Link} to="/">
                    <span className="d-block d-lg-none">Allen Wimmer</span>
                    <span className="d-none d-lg-block">
                        <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={require('../images/Profile.jpg')} alt="..." />
                    </span>
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse id="navbarResponsive" className="collapse navbar-collapse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav">
                        <NavItem>
                            <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-skills">Skills</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-education">Education</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-experience">Experience</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-military">Military</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </header>);

}

export default withApplicationInsights(NavMenu, 'NavMenu');
//export class NavMenu extends Component { 
//    static displayName = NavMenu.name;

//    constructor(props) {
//        super(props);

//        this.toggleNavbar = this.toggleNavbar.bind(this);
//        this.state = {
//            collapsed: true
//        };
//    }

//    toggleNavbar() {
//        this.setState({
//            collapsed: !this.state.collapsed
//        });
//    }

//    render() {
//        return (
//            <header>
//                <Navbar className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
//                    <NavbarBrand tag={Link} to="/">
//                        <span className="d-block d-lg-none">Allen Wimmer</span>
//                        <span className="d-none d-lg-block">
//                            <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={require('../images/Profile.jpg')} alt="..." />
//                        </span>
//                    </NavbarBrand>
//                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//                    <Collapse id="navbarResponsive" className="collapse navbar-collapse" isOpen={!this.state.collapsed} navbar>
//                        <ul className="navbar-nav">
//                            <NavItem>
//                                <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-skills">Skills</NavLink>
//                            </NavItem>
//                            <NavItem>
//                                <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-education">Education</NavLink>
//                            </NavItem>
//                            <NavItem>
//                                <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-experience">Experience</NavLink>
//                            </NavItem>
//                            <NavItem>
//                                <NavLink tag={Link} className="nav-Link js-scroll-trigger" to="/fetch-military">Military</NavLink>
//                            </NavItem>
//                        </ul>
//                    </Collapse>
//                </Navbar>
//            </header>
//        );
//    }
//}
