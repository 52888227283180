import React, { Component } from 'react';
import '../styles/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
        <div className="container-fluid p-0">
            <section className="resume-section" id="about">
                <div className="resume-section-content">
                    <h1 className="mb-0">
                        Allen 
                        <span className="text-primary"> Wimmer</span>
                    </h1>
                    <div className="subheading mb-5">
                        Avon, MN 56310 <span className="middot"> &middot; </span>  320.200.9641 <span className="middot"> &middot; </span> 
                        <a href="mailto:arwimmer@outlook.com">arwimmer@outlook.com</a>
                    </div>
                    <p className="lead mb-5">With 9 years of experience as a .NET Developer, I offer proven expertise in creating dependable, efficient, and secure
                        applications using the .NET framework. I excel in team collaboration, stay updated with industry trends, and am dedicated
                        to delivering top-notch code for every project.</p>
                    <div className="social-icons">
                        <a className="social-icon" href="https://www.linkedin.com/in/allenwimmerminnesota" target="_Blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    {/*    <a className="social-icon" href="#!"><FontAwesomeIcon icon={faGithub} /></a>*/}
                    </div>
                </div>
            </section>
      </div>
    );
  }
}
