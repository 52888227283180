import { Skills } from "./pages/FetchSkills";
import { Home } from "./pages/Home";
import  Experience  from "./pages/FetchExperience";
import  Military  from "./pages/FetchMilitary";
import  Education  from "./pages/FetchEducation";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/fetch-skills',
        element: <Skills />
    },
    {
        path: '/fetch-experience',
        element: <Experience />
    },
    {
        path: '/fetch-military',
        element: <Military />
    },
    {
        path: '/fetch-education',
        element: <Education />
    },

];

export default AppRoutes;