import React, { Component, useEffect, useMemo, useState } from 'react';
import '../styles/fetchmilitary.css';
import { useGetResume } from '../services/ResumeService'
import { FetchState, Postdata } from '../models/types'
import { withApplicationInsights } from '../components/telemetry';


const Military = () => {
    const [posts, fetchState, getPosts] = useGetResume();
    useEffect(() => {
        getPosts();
    }, [])
    return (
        <div className="container-fluid p-0">
            {fetchState === FetchState.LOADING && (
                <>
                    <p>Fetching posts...</p>
                </>
            )}
            {fetchState === FetchState.ERROR && (
                <>
                    <p>Oops! Something went wrong. Please try again.</p>
                </>
            )}
            {fetchState === FetchState.SUCCESS && (
                <>
                    <section className="resume-section" id="experience">
                        <div className="resume-section-content">
                            <h2 className="mb-5">Military</h2>
                            <div className="military-wrapper">
                                <div className="current-military-header">
                                    <h4>Current</h4>
                                </div>
                                <div className="military-unit">
                                    <img className="img-fluid img-profile mx-auto mb-2 unitcrest" src={posts!.military.currentUnitImage} alt="..." />
                                </div>
                                <div className="current-military">
                                    <ul className="current-ul ">
                                        <li className="font-weight-bold">{posts!.military.dateEntered}</li>
                                        <li><b>Rank: </b>{" " + posts!.military.rank}</li><li> <b>MOS:</b> {" " + posts!.military.mos} </li>
                                        <li><b>Current Position:</b>{" " + posts!.military.currentPosition + " "} </li><li><b>Current Unit: </b>{" " + posts!.military.currentUnit}</li>
                                        <li><b>Clearance:</b> {" " + posts!.military.clearance}</li>
                                    </ul>
                                </div><div className="current-military-header">
                                    <h4>Deployments</h4>
                                </div>
                                {posts!.military.deployments.map((deployment, index) => (
                                    <div className="military-deployment" key={index + "depl"}>
                                        <div className="military-unit" key={index + "depunit"}>
                                            <img className="img-fluid img-profile mx-auto mb-2 unitcrest" src={deployment.deploymentUnitImage} alt="..." key={index + "depimg"} />
                                        </div>
                                        <div className="current-military" key={index + "depinfo"}>
                                            <ul className="section-content current-ul" key={index + "deplist"}>
                                                <li key={index + deployment.location}>{deployment.location}</li>
                                                <li key={index + deployment.operation}> {deployment.operation + " " + deployment.date}</li>
                                                <li key={index + deployment.unit}>{deployment.unit}</li>
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    )


}
export default withApplicationInsights(Military, 'Military');

//export class Military extends Component {
//    static displayName = Military.name;

//    constructor(props) {
//        super(props);
//        this.state = { resumeData: [], loading: true };
//    }

//    componentDidMount() {
//        this.populateMilitaryData();
//    }

//    static renderMilitary(resumeData) {
//        return (
//            <div className="container-fluid p-0">
//                <section className="resume-section" id="experience">
//                    <div className="resume-section-content">
//                        <h2 className="mb-5">Military</h2>
//                        <div className="military-wrapper">
//                            <div className="current-military-header">
//                                <h4>Current</h4>
//                            </div>
//                            <div className="military-unit">
//                                <img className="img-fluid img-profile mx-auto mb-2 unitcrest" src={resumeData.Military.CurrentUnitImage} alt="..." />
//                            </div>
//                            <div className="current-military">
//                                <ul className="current-ul ">
//                                    <li className="font-weight-bold">{resumeData.Military.DateEntered}</li>
//                                    <li><b>Rank: </b>{" " + resumeData.Military.Rank}</li><li> <b>MOS:</b> {" " + resumeData.Military.MOS} </li>
//                                    <li><b>Current Position:</b>{" " + resumeData.Military.CurrentPostion + " "} </li><li><b>Current Unit: </b>{" " + resumeData.Military.CurrentUnit}</li>
//                                    <li><b>Clearance:</b> {" " + resumeData.Military.Clearance}</li>
//                                </ul>
//                            </div><div className="current-military-header">
//                                <h4>Deployments</h4>
//                            </div>
//                            {resumeData.Military.Deployments.map((deployment, index) => (
//                                <div className="military-deployment" key={index + "depl"}>
//                                    <div className="military-unit" key={index + "depunit"}>
//                                        <img className="img-fluid img-profile mx-auto mb-2 unitcrest" src={deployment.DeploymentUnitImage} alt="..." key={index + "depimg"} />
//                                    </div>
//                                    <div className="current-military" key={index + "depinfo"}>
//                                        <ul className="section-content current-ul" key={index + "deplist"}>
//                                            <li key={index + deployment.location}>{deployment.Location}</li>
//                                            <li key={index + deployment.Operation}> {deployment.Operation + " " + deployment.Date}</li>
//                                            <li key={index + deployment.Unit}>{deployment.Unit}</li>
//                                        </ul>
//                                    </div>
//                                </div>
//                            ))}
//                        </div>
//                    </div>
//                </section>
//            </div>
//        );
//    }

//    render() {
//        const contents = this.state.loading
//            ? <p><em>Loading...</em></p>
//            : Military.renderMilitary(this.state.resumeData);
//        return (
//            <div>
//                {contents}
//            </div>
//        );
//    }

//    async populateMilitaryData() {
//        fetchResume()
//            .then((response) => response.json())
//            .then((object) => {
//                this.setState({ resumeData: object, loading: false });
//            })
//    }
//}