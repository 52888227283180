import {  useEffect  } from 'react';
import '../styles/fetcheducation.css';
import { useGetResume } from '../services/ResumeService'
import { FetchState } from '../models/types'
import { withApplicationInsights } from '../components/telemetry';


const Education = () => {
    const [posts, fetchState, getPosts] = useGetResume();
    useEffect(() => {
        getPosts();
    },[])

    return (
        <div className="container-fluid p-0">
            {fetchState === FetchState.DEFAULT && (
                <>
                    <p>
                        Hello there, click the button below to get the list of posts from
                        the API.
                    </p>
                    <button onClick={getPosts}>Get Posts</button>
                </>
            )}
            {fetchState === FetchState.LOADING && <p>Fetching posts...</p>}
            {fetchState === FetchState.ERROR && (
                <>
                    <p>Oops! Something went wrong. Please try again.</p>
                    <button onClick={getPosts}>Get Posts</button>
                </>
            )}
            {fetchState === FetchState.SUCCESS && (
                <>
                    <section className="resume-section" id="experience">                      
                            <div className="resume-section-content">
                                <h2 className="mb-3">Education</h2>
                                <div className="education-wrapper">
                                    <div className="education-school-heading">
                                    <h3>{posts!.education.schoolName}</h3>
                                    </div>
                                    <div className="education-school-date">
                                    <span className="education-school-date-span text-primary certifications-content-ul"> {new Date(posts!.education.graduationDate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "2-digit" })}</span>
                                    </div>
                                    <div className="education-degree mb-2">
                                        <ul className="list-unstyled certifications-content-ul">
                                        <li>{posts!.education?.degree}</li>
                                        <li>{posts!.education?.field}</li>
                                        </ul>
                                    </div>
                                </div>
                                <h3>Certifications</h3>
                                <div className="certifications-content">
                                    <ul className="list-unstyled certifications-content-ul">
                                        {posts!.education.certifications.map((certification, index) => (
                                            <li className="ul-li-education" key={index}>{certification.certificationName} - {certification.certdate}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>                    
                    </section>
                </>
            )}
        </div>
    );
}

export default withApplicationInsights(Education, 'Education');

//export class Education extends Component {

//   static displayName = Education.name;
//    constructor(props) {
//        super(props);
//        this.state = { data: null, loading: true };
//    }

//    componentDidMount() {


//        this._useGetResume = useGetResume().then()
//    }

//    static renderEducation(ResumeBuilder) {
//        return (
//            <div className="container-fluid p-0">
//                <section className="resume-section" id="experience">
//                    <div className="resume-section-content">
//                        <h2 className="mb-3">Education</h2>
//                        <div className="education-wrapper">
//                            <div className="education-school-heading">
//                                <h3>{ResumeBuilder.Education.SchoolName}</h3>
//                            </div>
//                            <div className="education-school-date">
//                                <span className="education-school-date-span text-primary certifications-content-ul"> {new Date(ResumeBuilder.Education.GraduationDate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "2-digit" })}</span>
//                            </div>
//                            <div className="education-degree mb-2">
//                                <ul className="list-unstyled certifications-content-ul">
//                                    <li>{ResumeBuilder.Education.Degree}</li>
//                                    <li>{ResumeBuilder.Education.Field}</li>
//                                </ul>

//                            </div>
//                        </div>
//                        <h3>Certifications</h3>
//                        <div className="certifications-content">
//                            <ul className="list-unstyled certifications-content-ul">
//                                {ResumeBuilder.Education.Certifications.map((certification, index) => (
//                                    <li className="ul-li-education" key={index}>{certification.CertificationName} - {certification.Certdate}</li>
//                                ))}
//                            </ul>
//                        </div>
//                    </div>
//                </section>
//            </div>
//        );
//    }

//    render() {
//        const contents = this.state.loading
//            ? <p><em>Loading...</em></p>
//            : Education.renderEducation(this.state.ResumeBuilder);

//        return (
//            <div>
//                {contents}
//            </div>
//        );
//    }

//     async populateResumeData() {
//         useGetResume()
//             .then((response) => response.json())
//             .then((object) => {
//                 this.setState({ ResumeBuilder: object, loading: false })
//             })
//    }
//}